import {ParcelViewModel} from '@viewModels/Parcel.viewModel';
import {FlatList, ScrollView, View} from 'react-native';
import {ReceiveDeliveryStyles as styles} from '@modules/recipient/features/receive-delivery/ReceiveDelivery.styles';
import {List, ProgressBar} from 'react-native-paper';
import {Card} from '@components/Card';
import React, {useCallback} from 'react';
import {BodyGray} from '@components/texts/BodyGray';
import {BodyText} from '@components/texts/Body.text';
import {ParcelStatus} from '@config/Parcel.status';
import {useAppTheme} from '@config/Theme';

interface Props {
  loading: boolean;
  data: ParcelViewModel[];
}

export function ParcelListValidation({loading, data}: Props) {
  const theme = useAppTheme();
  const renderItem = useCallback(
    ({item}: {item: ParcelViewModel}) => (
      <List.Item
        title={
          <>
            <BodyGray>EAN: </BodyGray>
            <BodyText>{item.EAN}</BodyText>
          </>
        }
        left={props => (
          <List.Icon
            {...props}
            icon={item.status === ParcelStatus.Expired || item.status === ParcelStatus.InvalidCounter ? 'close-circle' : 'checkbox-marked-circle'}
            color={
              item.status === ParcelStatus.Collected
                ? theme.colors.success
                : item.status === ParcelStatus.Expired || item.status === ParcelStatus.InvalidCounter  ? theme.colors.error : theme.colors.backdrop
            }
          />
        )}
      />
    ),
    [],
  );
  return (
    <ScrollView
      style={styles.scrollView}
      contentContainerStyle={styles.scrollContainer}>
      {loading && <ProgressBar indeterminate />}
      <View style={styles.containerList}>
        <View style={styles.cardContainer}>
          <Card>
            <View style={styles.cardContent}>
              <FlatList
                scrollEnabled={false}
                data={data}
                renderItem={renderItem}
              />
            </View>
          </Card>
        </View>
      </View>
    </ScrollView>
  );
}
