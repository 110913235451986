import {StyleSheet, View} from 'react-native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {AuthStackParamList} from '../Auth.stack';
import {VerifyFeature} from '../features/verify/Verify.feature';
import React from 'react';
import {TempHeader} from '@components/TempHeader';

type Props = NativeStackScreenProps<AuthStackParamList, 'VERIFY'>;
export const VerifyPage = ({navigation, route}: Props) => {
  const back = () => {
    navigation.goBack();
  };

  return (
    <>
      <TempHeader back={back} />
      <View style={styles.container}>
        <VerifyFeature
          phone={route.params.phone}
          requestId={route.params.requestId}
        />
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
