import {View} from 'react-native';
import React from 'react';
import {IModalProps, styles} from './Modal.presets';
import Modal from 'react-native-modal';

export default function KModal(props: IModalProps) {
  const {
    children,
    height = 'auto',
    visible = false,
    onClose,
    swipeable = false,
  } = props;

  return (
    <Modal
      isVisible={visible}
      style={styles.modalContainer}
      useNativeDriverForBackdrop
      {...(swipeable && {swipeDirection: ['down']})}
      onSwipeComplete={() => onClose && onClose()}>
      <View
        style={[
          styles.container,
          {
            height: height === 'full' ? '100%' : 'auto',
          },
        ]}>
        <View style={styles.header}>
          <View style={styles.label} />
        </View>
        {children}
      </View>
    </Modal>
  );
}
