import {Text} from 'react-native-paper';
import {StyleSheet, useWindowDimensions, View} from 'react-native';
import {BottomButton} from "@components/BottomButton";
import KModal from "@components/UIKit/modal/Modal";
import {useTranslation} from "react-i18next";
import React from "react";

interface Props {
  show: boolean;
  onClose: () => void;
}

export const NewUserInfo = ({show, onClose}: Props) => {
  const { t } = useTranslation('recipient');
  const {height} = useWindowDimensions();

  return (
    <KModal visible={show}>
      <View style={{backgroundColor: 'white', height: height / 2 }}>
        <View style={{paddingHorizontal: 20}}>
          <Text style={styles.title}>{t(`recipient_newUser_title`)}</Text>
          <Text style={styles.text}>
            {t(`recipient_newUser_title`)}
          </Text>
          <Text style={styles.text}>
            {t(`recipient_newUser_paragraph_2`)}
          </Text>
          <Text style={[styles.text, { fontWeight: 'bold', color: '#374151' }]}>
            {t(`recipient_newUser_subtitle`)}
          </Text>
          <Text style={styles.text}>
            {t(`recipient_newUser_paragraph_3`)}
          </Text>
        </View>
        <BottomButton onPress={onClose} text={t('recipient_newUser_button')!} />
      </View>
    </KModal>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: 'Poppins-Bold',
    lineHeight: 34,
    textAlign: 'center',
    marginBottom: 20,
    color: '#374151',
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Poppins-Regular',
    lineHeight: 22,
    textAlign: 'center',
    color: '#6B7280',
    marginBottom: 10,
  }
});
