import React, {useEffect} from "react";

import {IconButton, Text} from "react-native-paper";
import {StyleSheet, View} from "react-native";
import {LinePoints, useSignature} from "@components/signature/Signature.provider";

interface Props {
  name: string,
  form: any,
  getSignatureFile: (file: File | null, signaturePoints: Array<LinePoints>) => void;
  error?: boolean;
}

export function Signature({name, form, getSignatureFile, error = false}: Props) {
  const {
    canvasRef,
    prepareCanvas,
    drawProvidedSignature,
    startDrawingMobile,
    finishDrawing,
    drawMobile,
    clearCanvas,
    file,
    signaturePoints,
  } = useSignature();

  useEffect(() => {
    prepareCanvas();
    if (form.values[name] !== null && form.values[name]?.length > 0) {
      drawProvidedSignature(form.values[name]);
    } else {
      clearCanvas();
    }
  }, [name]);

  useEffect(() => {
    getSignatureFile(file, signaturePoints);
  }, [file])

  return (
    <View style={[styles.container, error ? styles.errorInput : styles.normalInput]}>
      <View style={styles.header}>
        <Text style={styles.title}>Firma con el dedo</Text>
        <IconButton icon="refresh" onPress={clearCanvas} size={20} />
      </View>
      <canvas
        id='signatureCanvas'
        onTouchStart={startDrawingMobile}
        onTouchEnd={finishDrawing}
        onTouchMove={drawMobile}
        ref={canvasRef}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // @ts-ignore
    background: '#FCFCFF',
    borderRadius: 10,
  },
  normalInput: {
    // @ts-ignore
    border: '1px solid #EEE',
  },
  errorInput: {
    // @ts-ignore
    border: '1px solid #EA1D1DFF',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    backgroundColor: '#F5F5F5',
  },
  title: {
    marginTop: 10,
    fontSize: 16,
  },
})
