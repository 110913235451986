import {useEffect, useMemo, useReducer} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {AuthContext} from './AuthContext';
import {customAxios} from '../api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import {changeLanguage} from "@config/i18n";
import {getLanguageFromULocale} from "@utils/language";

enum AuthActionType {
  RESTORE = 'RESTORE',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
}

interface AuthAction {
  type: AuthActionType;
  payload: any;
}

export interface User {
  id: string;
  profile: {
    name: string;
    email: string;
    phone: string;
    dni: string;
    signaturePointsProvided: boolean;
    languageId: string;
  };
}

interface State {
  userToken: string | null;
  user: User | null;
  isLoading: boolean;
  isSignout: boolean;
  isProfileCompleted: boolean;
}

const authReducer = (
  prevState: State,
  action: AuthAction,
): {
  isLoading: boolean;
  userToken: string | null;
  isSignout: boolean;
  isProfileCompleted: boolean;
  user: User | null;
} => {
  switch (action.type) {
    case AuthActionType.RESTORE:
      return {
        ...prevState,
        userToken: action.payload.token,
        user: action.payload.user,
        isLoading: false,
        isProfileCompleted: !!action.payload.user.profile.name && !!action.payload.user.profile.dni && !!action.payload.user.profile.signature && action.payload.user.profile.signaturePointsProvided,
      };
    case AuthActionType.SIGN_IN:
      return {
        ...prevState,
        isSignout: false,
        userToken: action.payload.token,
        user: action.payload.user,
        isProfileCompleted: !!action.payload.user.profile.name && !!action.payload.user.profile.dni && !!action.payload.user.profile.signature && action.payload.user.profile.signaturePointsProvided,
      };
    case AuthActionType.SIGN_OUT:
      return {
        ...prevState,
        isSignout: true,
        userToken: null,
        user: null,
        isLoading: false,
        isProfileCompleted: false,
      };
    case AuthActionType.UPDATE_PROFILE:
      return {
        ...prevState,
        user: {
          id: prevState.user?.id!,
          profile: action.payload,
        },
        isProfileCompleted: !!action.payload.name && !!action.payload.dni && !!action.payload.signature && action.payload.signaturePointsProvided,
      };
  }
};

export const AuthContextProvider = ({children}: any) => {
  // @ts-ignore
  const [state, dispatch] = useReducer(authReducer, {
    isLoading: true,
    isSignout: false,
    isProfileCompleted: false,
    userToken: null,
    user: null,
  });

  const authContext = useMemo(
    () => ({
      signInWithProfile: (token: string, user: User) => {
        changeLanguage(getLanguageFromULocale(user.profile.languageId));
        return new Promise<void>((resolve) => {
          AsyncStorage.setItem('userToken', token);
          setInterceptors(token);
          dispatch({
            type: AuthActionType.SIGN_IN,
            payload: {token: token, user: user},
          });
          resolve();
        });
      },
      signIn: (token: string) => {
        return new Promise<void>((resolve, reject) => {
          AsyncStorage.setItem('userToken', token);
          setInterceptors(token);
          setTimeout(() => {
            customAxios
              .get(Endpoints.profile.me)
              .then(response => {
                changeLanguage(getLanguageFromULocale(response.data.profile.languageId));
                dispatch({
                  type: AuthActionType.RESTORE,
                  payload: {token: token, user: response.data},
                });
                resolve();
              })
              .catch(error => {
                console.log('error get profile', error);
                dispatch({
                  type: AuthActionType.SIGN_OUT,
                  payload: {token: null, user: null},
                });
                reject();
              });
          }, 100);
        });
      },
      signOut: () => {
        AsyncStorage.removeItem('userToken');
        dispatch({
          type: AuthActionType.SIGN_OUT,
          payload: {token: null, user: null, isLoading: false, isProfileCompleted: false},
        });
        setInterceptors(null);
      },
      signUp: async (_data: any) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `SecureStore`
        // In the example, we'll use a dummy token
        //TODO
      },
      updateProfile: async (_data: any) => {
        dispatch({
          type: AuthActionType.UPDATE_PROFILE,
          payload: _data,
        })
      },
      ...state,
    }),
    [state],
  );

  const setInterceptors = (token: string | null) => {
    customAxios.interceptors.request.clear();
    customAxios.interceptors.request.use(config => {
      config.headers!.Authorization = `Bearer ${token}`;
      return config;
    });
    customAxios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error && error.response && error.response.status === 401) {
          authContext.signOut();
        }
        return Promise.reject(error);
      },
    );
  };

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      try {
        const userToken = (await AsyncStorage.getItem('userToken')) ?? null;
        if (!userToken) {
          dispatch({
            type: AuthActionType.SIGN_OUT,
            payload: {token: null, user: null},
          });
          return;
        }
        // After restoring token, we may need to validate it in production apps
        setInterceptors(userToken);
        customAxios
          .get(Endpoints.profile.me)
          .then(response => {
            changeLanguage(getLanguageFromULocale(response.data.profile.languageId));
            dispatch({
              type: AuthActionType.RESTORE,
              payload: {token: userToken, user: response.data},
            });
          })
          .catch(error => {
            console.log('error get profile', error);
            authContext.signOut();
          });
      } catch (e) {
        // Restoring token failed
      }
    };

    bootstrapAsync();
  }, []);

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};
