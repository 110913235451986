import { theme } from '@config/Theme';
import { Linking, Platform, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';

interface Props {
  address: string;
  text: string
  lat: number;
  lng: number;
  placeId?: string;
}

export const MapTo = ({address, text, lat, lng, placeId}: Props) => {
  const mapTo = async () => {
    const destination = encodeURIComponent(`${address}`);
    let link = `https://www.google.com/maps/search/?api=1&query=${destination}&z=3`;

    if (lat !== 0 && lng !== 0 && placeId) {
      link = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}&query_place_id=${placeId}`;
    }

    try {
      const supported = await Linking.canOpenURL(link);

      if (supported) {
        Linking.openURL(link);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button
      icon="directions"
      onPress={mapTo}
      mode="outlined"
      labelStyle={{color: theme.colors.onBackground}}
      style={styles.button}
    >
      {text}
    </Button>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'white',
    borderColor: theme.colors.primary,
    borderWidth: 1,
    paddingHorizontal: 15,
  },
});
