import {theme} from '@config/Theme';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {StyleSheet, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import {BackHeader} from '@components/BackHeader';
import {LanguageConfiguration} from "@modules/profile/features/configuration/LanguageConfiguration";

export const ConfigurationPage = ({navigation}: any) => {
  const {t} = useTranslation('profile');



  return (
    <SafeAreaView style={styles.container}>
      <BackHeader
        title={t('configuration_page_title')!}
        back={navigation.goBack}
      />
      <View style={styles.innerContainer}>
        <LanguageConfiguration />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {flex: 1, backgroundColor: theme.colors.background},
  innerContainer: {
    flex: 1,
    padding: 20,
  },
  title: {fontWeight: 'bold', fontSize: 20, color: 'black'},
});
