import {LanguageSelector} from "@components/LanguageSelector";
import React, {useEffect, useState} from "react";
import {LanguageResource} from "@modules/profile/features/configuration/resources/Language.resource";
import {Language} from "@modules/profile/features/configuration/resources/Language.apiRequest";

export const LanguageConfiguration = () => {
  const [languages, setLanguages] = useState<Array<Language>>([]);

  useEffect(() => {
    LanguageResource.getLanguages().then((response) => {
      setLanguages(response.data.items);
    })
  }, []);

  return (
    <>
      <LanguageSelector languages={languages} />
    </>
  )
}
