import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
//https://smucode.github.io/react-world-flags/
const apiKey = 'vPCfaww4kzPRNBo3JzzksA';
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18next
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'es',

    ns: ['default'],
    defaultNS: 'default',

    supportedLngs: ['es', 'en', 'pt'],

    backend: {
      loadPath,
    },
  });

export const changeLanguage = (lng: string) => {
  i18next.changeLanguage(lng);
};

export const getLanguage = () => {
  return i18next.language;
};
//
