import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {LoginPage} from './pages/Login.page';
import {VerifyPage} from './pages/Verify.page';

export type AuthStackParamList = {
  CHECK_ACTION: undefined;
  LOGIN: undefined;
  VERIFY: { requestId: string; phone: string; countryCode: string };
  SIGNATURE: undefined;
};
export const AuthStack = () => {
  const Stack = createNativeStackNavigator<AuthStackParamList>();
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}
                     initialRouteName={"LOGIN"}>
      <Stack.Screen name={'LOGIN'} component={LoginPage}/>
      <Stack.Screen name={'VERIFY'} component={VerifyPage}/>
    </Stack.Navigator>);
};
