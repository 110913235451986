import {Appbar} from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import {responsiveHeight} from 'react-native-responsive-dimensions';

interface Props {
  onPress: () => void;
}
export const CustomBack = ({onPress}: Props) => {
  return (
    <View style={styles.back}>
      <Appbar.BackAction size={20} onPress={onPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  back: {
    backgroundColor: 'white',
    borderRadius: 10,
    marginLeft: 10,
    elevation: responsiveHeight(5),
    shadowRadius: responsiveHeight(2),
    shadowOpacity: 0.1,
    zIndex: 2,
  },
});
