import {customAxios} from "@infrastructure/api/CustomAxios";
import {Endpoints} from "@config/Endpoints";

export const LanguageResource = {
  getLanguages: () => {
    return customAxios.get(Endpoints.profile.languages);
  },
  updateLanguage: (languageId: string) => {
    return customAxios.patch(Endpoints.profile.setLanguage, { languageId });
  }
}
