import * as process from 'process';
import {MultiAppConfig} from './MultiAppConfig';

const REACT_BASE_API_URL = process.env.REACT_BASE_API_URL;
const REACT_APP_MQTT_URL =
  process.env.REACT_APP_MQTT_URL;

const role = MultiAppConfig.ROLE.toLowerCase();

export const Endpoints = {
  app: {
    config: `/v1/${role}/appConfig`,
    error: `/v1/${role}/appErrors`,
  },
  auth: {
    register: `/v1/${role}/auth/register`,
    companies: `/v1/${role}/companies`,
    request_verify: `/v1/${role}/auth/login-first-step`,
    verify: `/v1/${role}/auth/login-second-step`,
    login_courier: `/v1/${role}/auth/login-courier`,
  },
  recipient: {
    invitation: (invitationId: string) => `/v1/${role}/invitation/${invitationId}`,
  },
  notification: {
    registerDevice: `/v1/${role}/notifications/devices`,
  },
  profile: {
    me: `/v1/${role}/profile/me`,
    uploadSignature: `/v1/uploads/signature`,
    languages: `/v1/languages`,
    setLanguage: `/v1/${role}/profile/language`,
  },
  action: {
    check: (id: string) => `/v1/${role}/parcels/group/${id}/action`,
  },
  faqs: {
    list: `/v1/${role}/faqs`,
  },
  upload: {
    parcel: '/v1/uploads/parcel',
    profile: '/v1/uploads/profile',
  },
  attachments: {
    addParcel: `/v1/${role}/parcelAttachments`,
    removeParcel: (id: string) => `/v1/${role}/parcelAttachments/${id}`,
  },
  kanguroPoints: {
    nearby: `/v1/${role}/kanguroPoints/nearby`,
    byCodeAndDelivery: (code: string, deliveryId: string) =>
      `/v1/${role}/kanguroPoints/code/${code}/delivery/${deliveryId}`,
    getById: (id: string) => `/v1/${role}/kanguroPoints/${id}`,
  },
  parcels: {
    history: `/v1/${role}/parcels/history`,
    current: `/v1/${role}/parcels/current`,
    currentDeliveryDrivers: `/v1/${role}/parcels/current/deliveryDrivers`,
    currentRecipients: `/v1/${role}/parcels/current/recipients`,
    future: `/v1/${role}/parcels/future/deliveryDrivers`,
    currentByKanguroPoint: `/v1/${role}/parcels/current/kanguroPoints`,
    get: (id: string) => `/v1/${role}/parcels/${id}`,
    getByCode: (code: string) => `/v1/${role}/parcels/code/${code}`,
    validate: (id: string) => `/v1/${role}/parcels/${id}`,
    requestConfirmDelivery: (id: string) =>
      `/v1/${role}/parcels/counter/${id}/confirm`,
    confirmParcel: (id: string) => `/v1/${role}/parcels/${id}/confirm-parcels`,
    finish: (id: string) => `/v1/${role}/parcels/${id}`,
    cancel: (id: string) => `/v1/${role}/parcels/${id}`,
    cancelValidation: (id: string) =>
      `/v1/${role}/parcels/${id}/cancel-validation`,
    byGroup: (groupId: string) => `/v1/${role}/parcels/group/${groupId}`,
    cancelByGroup: (groupId: string) =>
      `/v1/${role}/parcels/group/${groupId}/cancel-validation`,
    validating: `/v1/${role}/parcels/validating`,
    create: `/v1/${role}/parcels`,
    update: (id: string) => `/v1/${role}/parcels/${id}`,
    recipientInfo: (phone: number) => `/v1/${role}/parcels/recipients/${phone}`,
    createRecipient: `/v1/${role}/parcels/recipients`,
  },
  incidences: {
    otp: '/v1/incidences',
  },
  rating: {
    rate: `/v1/${role}/ratings`,
  }
};

export const SERVER_URL = `${REACT_BASE_API_URL}/`;
export const API_URL = `${REACT_BASE_API_URL}/api`;
export const MQTT_URL = `${REACT_APP_MQTT_URL}/mqtt`;
