import {styles} from '@modules/auth/features/verify/Verify.styles';
import {KeyboardAvoidingView, Platform, Text, View,} from 'react-native';
import {useEffect, useRef, useState} from 'react';
import {VerifyResource} from './resources/Verify.resource';
import {AxiosResponse} from 'axios';
import {useAuth} from '@auth/useAuth';
import {CodeVerification} from '@components/code-verification/CodeVerification';
import {MultiAppConfig, ROLE} from '@config/MultiAppConfig';
import {User} from '@auth/AuthContextProvider';
import {Stack} from "@components/Stack";
import {useTranslation} from "react-i18next";
import {H1Text} from "@components/texts/H1.text";
import CountdownButton from "@components/CountdownButton";
import {isInvitationURL} from "@utils/url";
import {useNavigation} from "@react-navigation/native";
import {Button} from '@components/UIKit';
import IncidenceOtp from '@modules/auth/components/IncidenceOtp/IncidenceOtp';
import Toast from "react-native-toast-message";

interface Props {
  phone: string;
  requestId: string;
}

export const VerifyFeature = ({phone, requestId: oldRequestId}: Props) => {
  const {t} = useTranslation(['auth','incidences']);
  const {signInWithProfile, isProfileCompleted, userToken} = useAuth();
  const [requestId, setRequestId] = useState<string>(oldRequestId);
  const [error, setError] = useState('');
  const codeRef = useRef();
  const [incidenceModal, setIncidenceModal] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    if (userToken && isInvitationURL()) {
      const nav = "Receive";
      // @ts-ignore
      navigation.navigate(nav)
    }
  }, [userToken, isProfileCompleted]);

  const onVerify = (verificationCode: string) => {
    VerifyResource.verify({requestId, verificationCode})
      .then(
        (
          res: AxiosResponse<{
            accessToken: string;
            recipient?: User;
          }>,
        ) => {
          const userRole = MultiAppConfig.ROLE.toLowerCase();
          if (!(userRole in res.data)) {
            setError('Código incorrecto');
            return;
          }
          signInWithProfile(
            res.data.accessToken,
            res.data[userRole as ROLE]!,
          );
        },
      )
      .catch(() => {
        setError('Código incorrecto');
      });
  };

  const onRequest = () => {
    setError('');
    // @ts-ignore
    codeRef.current!.empty();
    VerifyResource.requestCode(phone)
      .then((res: AxiosResponse<{requestId: string}>) => {
        setRequestId(res.data.requestId);
        Toast.show({
          type: 'success',
          text1: t('verifyPage_code_sent')!,
        });
      })
      .catch(() => {
        setError(
          'Error al enviar el código, espera 2 minutos y vuelve a intentar',
        );
      })
  };
  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.keyboard}>
        <View>
          <H1Text style={{textAlign: 'center'}}>{t('login_page_title')!}</H1Text>
          <Text style={styles.textdesc}>
            {t('verify_feature_subtitle', {phone})}
          </Text>
          <View style={styles.form}>
            <CodeVerification
              numberOfInputs={4}
              onFilled={newCode => {
                onVerify(newCode);
              }}
              ref={codeRef}
            />
          </View>
          <Stack spacing={3}>
            <Text style={styles.error}>{error}</Text>
            <CountdownButton onPress={onRequest}/>
          </Stack>
          <View style={styles.incidenceButton}>
            <Button
              type="text"
              text={t('verifyPage_incidences_btn', {ns: 'incidences'})!}
              onPress={() => setIncidenceModal(true)}
            />
          </View>
        </View>
      </KeyboardAvoidingView>
      <IncidenceOtp
        active={incidenceModal}
        onClose={() => setIncidenceModal(false)}
        userPhone={phone}
      />
    </>
  );
};
