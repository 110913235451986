import React, {useEffect, useState} from 'react';
import {changeLanguage, getLanguage} from '@config/i18n';
import {KDropdown, KDropItem} from '@components/KDropdown';
import {Language} from "@modules/profile/features/configuration/resources/Language.apiRequest";
import {LanguageResource} from "@modules/profile/features/configuration/resources/Language.resource";

interface Props {
  languages: Language[];
}

export const LanguageSelector = ({ languages }: Props) => {
  const [value, setValue] = useState(getLanguage());
  const [items, setItems] = useState<Array<KDropItem>>([]);

  useEffect(() => {
    languages && setItems(languages.map((language) => ({ label: language.name, value: language.shortCode })));
  }, [languages]);

  const setLanguage = (languageCode: string) => {
    if (getLanguage() === languageCode) {
      return;
    }
    const lng = languages.find((language) => language.shortCode === languageCode)

    if (lng) {
      LanguageResource.updateLanguage(lng.id).then(() => {
        changeLanguage(languageCode);
        setLanguage(languageCode);
      });
    }
  }

  return <KDropdown items={items} value={value} onSelect={setLanguage} />;
};
