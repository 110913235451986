import {StyleSheet, View} from 'react-native';
import {theme} from '@config/Theme';
import {ReceiveDeliveryFeature} from '../../features/receive-delivery/ReceiveDelivery.feature';
import {useAuth} from "@auth/useAuth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useCallback, useEffect, useState} from "react";
import {Loading} from "@components/Loading";
import {BackHeader} from "@components/BackHeader";
import {useTranslation} from "react-i18next";

export const ReceiveDeliveryPage = ({navigation, route}: any) => {
  let {counterId, signed, containsUpsParcels} = route.params;
  const {user, signOut, isProfileCompleted} = useAuth();
  const {t} = useTranslation('recipient');
  const [guid, setGuid] = useState<string>(counterId)
  const [upsParcels, setUpsParcels] = useState<boolean | null>(containsUpsParcels);

  const back = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      signOut()
      window.location.reload()
    }
  };

  const redirectToSignature = () => {
    navigation.navigate('Signature', {
      counterId: guid,
      containsUpsParcels: upsParcels,
    });
  };

  useEffect(() => {
    if (guid === '0') {
      AsyncStorage.getItem('invitation_ids').then((value) => {
        if (!value) return;
        try {
          const ids = JSON.parse(value!)

          setGuid(ids.counterId)
          setUpsParcels(ids.containsUpsParcels)
        } catch (error) {
          window.location.reload()
        }
      })
    } else {
      AsyncStorage.removeItem('invitation_ids').then((value) => {})
    }

  }, [guid])

  if (!signed && upsParcels) {
    redirectToSignature()
    return;
  }
  if (!signed && !upsParcels && !isProfileCompleted) {
    redirectToSignature();
    return;
  }

  if (guid === '0') return (<Loading />)

  return (
    <>
      <BackHeader back={back} title={t('receiveDelivery_page_title')!} />
      <View style={styles.container} key={user.profile.dni}>
        <ReceiveDeliveryFeature back={back} id={guid} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
    backgroundColor: theme.colors.background,
    flex: 1,
  },
});
