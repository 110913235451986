import {ScrollView, StyleSheet, View} from 'react-native';
import {theme} from '@config/Theme';
import {SignatureFeature} from "@modules/invitation/features/Signature.feature";
import {Header} from "@components/Header";
import React from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "@auth/useAuth";
import {useEvent} from "@events/hooks/useEvent";

interface Props {
  navigation: any;
  route: any;
}

export const SignaturePage = ({navigation, route}: Props) => {
  const {t} = useTranslation('invitation');
  const [isDrawing, setIsDrawing] = React.useState(false);
  const {signOut} = useAuth();
  const back = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      signOut()
      window.location.reload()
    }
  };
  useEvent('startDrawing', () => {
    setIsDrawing(true)
  });
  useEvent('finishDrawing', () => {
    setIsDrawing(false)
  });

  return (
    <View style={{flex: 1, backgroundColor: theme.colors.background}}>
      <Header
        title={t('signature_back')!}
      />
      <ScrollView
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps="always"
        scrollEnabled={!isDrawing}
      >
        <SignatureFeature
          counterId={route.params.counterId}
          containsUps={route.params.containsUpsParcels}
        />
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
  },
});
