import {
  KanguroPointLockerDetailsPage
} from '@modules/recipient/pages/receive-delivery/KanguroPointLockerDetails.page';
import { OpenLockerPage } from '@modules/recipient/pages/receive-delivery/OpenLocker.page';
import {InitialPage} from './Initial.page';
import {ReceiveDeliveryPage} from './ReceiveDelivery.page';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {KanguroPointReceiveDetailsPage} from './KanguroPointReceiveDetails.page';
import {ParcelDeliveredPage} from '@modules/recipient/pages/receive-delivery/ParcelDelivered.page';
import {DownloadAppPage} from "@modules/invitation/pages/DownloadApp.page";
import {isDownloadURL} from "@utils/url";
import {SignaturePage} from "@modules/invitation/pages/Signature.page";

export const ReceiveDeliveryStack = () => {
  const Stack = createNativeStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{headerShown: false}}
      initialRouteName={ isDownloadURL() ? "DownloadApp" : "Initial" }
    >
      <Stack.Screen name="Initial" component={InitialPage} options={{}} />
      <Stack.Screen name="Receive" component={ReceiveDeliveryPage} />
      <Stack.Screen name="Confirm" component={ParcelDeliveredPage} />
      <Stack.Screen
        name="KanguroPointReceiveDetails"
        component={KanguroPointReceiveDetailsPage}
      />
      <Stack.Screen
        name="KanguroPointLockerDetails"
        component={KanguroPointLockerDetailsPage}
      />
      <Stack.Screen name="OpenLocker" component={OpenLockerPage} />
      <Stack.Screen name="DeliveryFinished" component={ParcelDeliveredPage} />
      <Stack.Screen name="DownloadApp" component={DownloadAppPage} />
      <Stack.Screen name="Signature" component={SignaturePage} />
    </Stack.Navigator>
  );
};
