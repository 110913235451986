import {StyleSheet, View} from 'react-native';
import {Text} from 'react-native-paper';
import {CommonActions} from '@react-navigation/native';
import {Highfive} from '@components/highfive';
import {BottomButton} from '@components/BottomButton';
import {isInvitationURL, setDownloadURL} from "../../../../utils/url";
import {RatingFeature} from "@modules/common/features/rating/Rating.feature";
import {useTranslation} from "react-i18next";

export const ParcelDeliveredPage = ({navigation, route}: any) => {
  const {t} = useTranslation('recipient');
  const {groupId} = route.params;
  const back = () => {
    if (isInvitationURL())  {
      setDownloadURL();
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{name: 'Initial'}],
        }),
      );
    }
  };

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{paddingHorizontal: 20, marginTop: 50}}>
        <Text style={styles.title}>{' '}
          {t('parcelDelivered_page_subtitle')!},{' '}</Text>
        <Text style={styles.text}>
          {t('parcelDelivered_page_description')!},
        </Text>
      </View>
      <Highfive />
      <BottomButton onPress={back} text={t('parcelDelivered_page_submit')!} />
      <RatingFeature groupId={groupId}/>
    </View>
  );
};
const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    fontWeight: '900',
    fontFamily: 'Poppins-Bold',
    lineHeight: 36,
    textAlign: 'left',
    marginBottom: 20,
  },
  text: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Poppins-Regular',
    lineHeight: 24,
    textAlign: 'left',
    color: '#9796A1',
  },
});
