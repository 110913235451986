import {Text} from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import {ScheduleViewModel} from '@viewModels/Schedule.viewModel';
import {useTranslation} from 'react-i18next';
import {BodyGray} from '@components/texts/BodyGray';
import {theme} from "@config/Theme";
import {weekDays} from "@infrastructure/Utils";

interface Props {
  schedule: Array<ScheduleViewModel>;
}



export const KanguroPointScheduleList = ({schedule}: Props) => {
  const {t} = useTranslation('common');

  const scheduleByDay = schedule.reduce((prev, acc) => {
    const dayOfWeek = acc.dayOfWeek;
    if (!prev[dayOfWeek]) {
      prev[dayOfWeek] = [];
    }
    prev[acc.dayOfWeek].push(acc);
    return prev;
  }, [] as any);

  if (schedule.length === 0) return null;

  return (
    <View style={{paddingTop: 16}}>
      {weekDays.map((s: any, i: any) => (
        <View key={i} style={{flexDirection: 'row', paddingBottom: 10}}>
          <BodyGray style={styles.weekdays}>{t(s)}</BodyGray>
          <View style={{flex: 0.5, justifyContent: 'space-evenly'}}>
            {`${i}` in scheduleByDay ? (
              scheduleByDay[`${i}`].map((interval: any) => (
                <View
                  key={interval.id}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    flexGrow: 1,
                  }}>
                  <Text variant="bodySmall" style={{flex: 0.3}}>
                    {interval.open}
                  </Text>
                  <Text variant="bodySmall" style={{flex: 0.1}}>
                    -
                  </Text>
                  <Text variant="bodySmall" style={{flex: 0.3}}>
                    {interval.close}
                  </Text>
                </View>
              ))
            ) : (
              <View
                key={'closed' + i}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignContent: 'center',
                  flex: 1,
                }}>
                <Text
                  variant="bodySmall"
                  style={{flex: 1, alignSelf: 'center', textAlign: 'left'}}>
                  {t('kanguropointScheduleList_closed')}
                </Text>
              </View>
            )}
          </View>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  weekdays: {
    fontSize: 13,
    fontWeight: '400',
    fontFamily: 'Poppins-Regular',
    color: theme.colors.info,
    flex: 0.6,
  },
});
