import {theme} from '@config/Theme';
import {normalizeVertical} from '@utils/normalize';
import {StyleSheet} from 'react-native';

const {
  uikit: {colors},
} = theme;
export interface IModalProps {
  height?: 'full' | 'auto';
  children: React.ReactNode;
  visible: boolean;
  onClose?: () => void;
  swipeable?: boolean;
}

export const styles = StyleSheet.create({
  modalContainer: {margin: 0, justifyContent: 'flex-end'},
  container: {
    backgroundColor: colors.white,
    minHeight: normalizeVertical(200),
    borderTopRightRadius: 42,
    borderTopLeftRadius: 42,
  },
  header: {
    height: 68,
    borderBottomWidth: 0,
    borderBottomColor: colors.coolGray200,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 24,
  },
  label: {
    height: 4,
    width: 40,
    backgroundColor: colors.coolGray200,
    borderRadius: 30,
  },
});
