import {ListCurrentKanguroPointsFeature} from '@modules/recipient/features/list-current-kanguro-points/ListCurrentKanguroPoints.feature';

export const InitialPage = ({navigation}: any) => {
  return (
    <>
      <ListCurrentKanguroPointsFeature
        openDelivery={currentKanguroPoint => {
          if (currentKanguroPoint.counter) {
            navigation.navigate('KanguroPointReceiveDetails', {
              id: currentKanguroPoint.id,
              counterId: currentKanguroPoint.counter.id,
              containsUpsParcels: currentKanguroPoint.containsUpsParcels,
            });
          } else {
            navigation.navigate('KanguroPointLockerDetails', {
              id: currentKanguroPoint.id,
            });
          }
        }}
      />
    </>
  );
};
