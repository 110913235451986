import {Image, View} from 'react-native';
import {theme} from '@config/Theme';
import React from 'react';
import SmallLogo from '@assets/img/logo-blanc-groc.png';
import {Appbar} from "react-native-paper";
import {responsiveHeight} from "react-native-responsive-dimensions";

export const TempHeader = ({back}: {
  back: () => void | boolean;
}) => {
  return (
    <Appbar.Header
      mode="center-aligned"
      statusBarHeight={0}
      style={styles.container}>
      <View style={styles.back}>
        <Appbar.BackAction size={20} onPress={back} />
      </View>
      <Image source={SmallLogo} style={styles.image} resizeMode="contain" />
    </Appbar.Header>
  );
};

const styles = {
  container: {
    width: '100%',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    overflow: 'hidden' as 'hidden',
    position: 'relative' as 'relative',
    zIndex: 0,
    backgroundColor: theme.colors.primary,
  },
  back: {
    position: 'absolute' as 'absolute',
    left: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    marginLeft: 10,
    elevation: responsiveHeight(5),
    shadowRadius: responsiveHeight(2),
    shadowOpacity: 0.1,
    zIndex: 2,
  },
  image: {
    width: 75,
    height: 75,
  },
};
