import {InvitationApiResponse} from "@resources/Invitation.apiResponse";
import {InvitationCounterViewModel} from "@viewModels/InvitationCounter.viewModel";
import {InvitationLockerViewModel} from "@viewModels/InvitationLocker.viewModel";
import {InvitationRecipientViewModel} from "@viewModels/InvitationRecipient.viewModel";

export class InvitationViewModel {
  id: string;
  address: string;
  longitude: number;
  latitude: number;
  parcelsCount: number;
  containsUpsParcels: boolean;
  counter: InvitationCounterViewModel | null;
  locker: InvitationLockerViewModel | null;
  recipient: InvitationRecipientViewModel;
  attachments: Array<{type: string, url: string}>

  constructor(apiResponse: InvitationApiResponse) {
    this.id = apiResponse.id;
    this.address = apiResponse.address;
    this.longitude = apiResponse.longitude;
    this.latitude = apiResponse.latitude;
    this.parcelsCount = apiResponse.parcelsCount;
    this.containsUpsParcels = apiResponse.containsUpsParcels;
    this.counter = apiResponse.counter
      ? new InvitationCounterViewModel(apiResponse.counter)
      : null;
    this.locker = apiResponse.locker
      ? new InvitationLockerViewModel(apiResponse.locker)
      : null;
    this.recipient = new InvitationRecipientViewModel(apiResponse.recipient)
    this.attachments = apiResponse.attachments;
  }
}
