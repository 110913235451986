import {Dropdown} from 'react-native-element-dropdown';
import React from 'react';
import {StyleSheet} from 'react-native';

export interface KDropItem {
  label: string;
  value: any;
}

interface Props {
  items: Array<KDropItem>;
  onSelect: (value: any) => void;
  value: any;
  placeholder?: string;

  //[key: string]: Partial<DropdownProps<any>>;?
  [key: string]: any;
}

export const KDropdown = ({
  items,
  onSelect,
  value,
  placeholder,
  ...other
}: Props) => {
  return (
    <Dropdown
      style={styles.dropdown}
      placeholderStyle={styles.placeholderStyle}
      data={items}
      placeholder={placeholder}
      labelField="label"
      valueField="value"
      value={value}
      onChange={item => {
        onSelect(item.value);
      }}
      {...other}
    />
  );
};

const styles = StyleSheet.create({
  dropdown: {
    paddingHorizontal: 10,
    height: 50,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 10,
    backgroundColor: 'white',
  },
  placeholderStyle: {
    color: 'gray',
  },
});
