import {ScheduleViewModel} from "@viewModels/Schedule.viewModel";
import {InvitationCounterApiResponse} from "@resources/InvitationCounter.apiResponse";
import {ProfileViewModel} from "@viewModels/Profile.viewModel";
import {InvitationRecipientApiResponse} from "@resources/InvitationRecipient.apiResponse";

export class InvitationRecipientViewModel {
  id: string;
  phone: string;
  languageId: string;

  constructor(invitationRecipientApiResponse: InvitationRecipientApiResponse) {
    this.id = invitationRecipientApiResponse.id;
    this.phone = invitationRecipientApiResponse.profile.phone
    this.languageId = invitationRecipientApiResponse.profile.languageId
  }
}
